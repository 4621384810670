//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideYUpTransition } from "vue2-transitions";
import Checkbox from "@/components/Checkbox.vue";
export default {
  props: {
    items: {
      required: true
    },
    value: {
      default: []
    },
    openUp: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    width: {
      default: 200
    }
  },
  data() {
    //console.log(this.value.length, this.items.length);
    return {
      isOpen: false,
      selectAll: this.value.length === this.items.length
    };
  },
  computed: {
    title() {
      var _this$items$, _this$items$2, _this$items$3, _this$items$4;
      if (!this.value.length) {
        return "Не выбрано";
      }
      if (((_this$items$ = this.items[0]) === null || _this$items$ === void 0 ? void 0 : _this$items$.datasource) === "wb" || ((_this$items$2 = this.items[0]) === null || _this$items$2 === void 0 ? void 0 : _this$items$2.datasource) === "ozon") if (this.value.length === Object.keys(this.items).length) {
        return "Все магазины";
      }
      if (((_this$items$3 = this.items[0]) === null || _this$items$3 === void 0 ? void 0 : _this$items$3.type) === "types") if (this.value.length === Object.keys(this.items).length) {
        return "Все операции";
      }
      if (((_this$items$4 = this.items[0]) === null || _this$items$4 === void 0 ? void 0 : _this$items$4.type) === "storage") if (this.value.length === Object.keys(this.items).length) {
        return "Все склады";
      }
      return this.value.map(i => i.title).join(", ");
    }
  },
  methods: {
    isChecked(item) {
      return !!this.value.find(i => i.id === item.id);
    },
    onCheckbox(v, item) {
      let newValue = this.value.filter(i => i.id !== item.id);
      if (v) {
        newValue.push(item);
      }
      this.$emit("input", newValue);
    },
    closeSelect() {
      this.isOpen = false;
    },
    openSelect() {
      this.isOpen = true;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
    }
  },
  components: {
    SlideYUpTransition,
    Checkbox
  },
  watch: {
    selectAll() {
      const newValue = this.selectAll ? this.items : [];
      this.$emit("input", newValue);
    },
    items() {
      if (this.value.length === this.items.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  }
};
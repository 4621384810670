//
//
//
//
//
//
//
//
//
//
//

import LoadingIndicator from "@/components/Loading/Indicator";
export default {
  components: {
    LoadingIndicator
  }
};
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    gap: {
      type: String,
      validate: v => ["16", "24"].includes(v),
      default: "16"
    }
  },
  data() {
    return {
      scrollSettings: {
        suppressScrollX: false,
        suppressScrollY: true,
        wheelPropagation: true
      }
    };
  }
};
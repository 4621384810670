//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      loading: false,
      onComplete: null,
      type: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      type
    }) {
      this.type = type;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        this.loading = false;
        close();
        this.onComplete && this.onComplete();
        this.$emit("complete");
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    actions() {
      return [{
        name: "submit",
        text: "Ок",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};